<template>
  <!-- ======= Contact Us Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Contact Us</h2>
      </div>

      <div class="row">

        <div class="col-lg-6 d-flex" data-aos="fade-up">
          <div class="info-box">
          <i class="bx bx-map"></i>
          <h3>Our Address</h3>
          <p>{{ contactInfo.address }}</p>
          </div>
        </div>

        <div class="col-lg-6 d-flex" data-aos="fade-up" data-aos-delay="100">
          <div class="info-box">
          <i class="bx bx-envelope"></i>
          <h3>Email Us</h3>
          <p>{{ contactInfo.email }}</p>
          </div>
        </div>

        <div class="col-lg-12" data-aos="fade-up" data-aos-delay="300">
          <form role="form" class="php-email-form" @submit.prevent="submit">
            <div class="row">
              <div class="col-lg-6 form-group">
              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
              </div>
              <div class="col-lg-6 form-group">
              <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Us Section -->
</template>
<script setup>
import { defineProps } from 'vue';
const props = defineProps({
  contactInfo: {
    type: Object
  }
});

/**
 * Easy selector helper function
 */
const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

function submit() {
  let subject = select('.php-email-form input[name="subject"]').value;
  let message = select('.php-email-form textarea[name="message"]').value.replace(/\n/g, "%0D%0A");
  let body = `Name%3A%20${select('.php-email-form input[name="name"]').value}%0D%0AEmail%3A%20${select('.php-email-form input[name="email"]').value}%0D%0AMessage%3A%20${message}`;
  let url = `mailto:${props.contactInfo.email}?subject=${subject}&body=${body}`;
  window.open(url);
}
</script>