<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Services</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
          <div class="icon"><i class="bi bi-chat-left-dots"></i></div>
          <h4 class="title"><a href="">Public Cloud Professional Services</a></h4>
          <p class="description">Our Public Cloud Professional Services offer comprehensive expertise and guidance in leveraging public cloud platforms, specifically AWS, to drive digital transformation for your business. Our team of AWS-certified professionals assists you in designing and implementing scalable, secure, and cost-effective cloud solutions tailored to your unique requirements. Whether you need assistance with cloud strategy, migration, architecture design, or optimization, our experts are here to ensure a seamless transition to the public cloud and help you harness its full potential.</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="bi bi-bounding-box"></i></div>
          <h4 class="title"><a href="">New Age Managed Services</a></h4>
          <p class="description">Our New Age Managed Services provide proactive and comprehensive management of your cloud infrastructure on AWS. We take care of day-to-day operations, monitoring, and optimization to ensure your cloud environment is running smoothly and efficiently. Our team leverages advanced monitoring tools and automation to proactively identify and resolve issues, optimize performance, and enhance security. With our managed services, you can focus on your core business while we handle the complexities of managing your cloud infrastructure.</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i class="bi bi-globe"></i></div>
          <h4 class="title"><a href="">Website Acceleration</a></h4>
          <p class="description">Our Website Acceleration service is designed to improve the performance and user experience of your website. We utilize various techniques such as content delivery network (CDN) integration, caching mechanisms, image optimization, and code optimization to optimize your website's speed and responsiveness. By reducing page load times and enhancing overall performance, we help you increase user engagement, improve search engine rankings, and boost conversion rates.</p>
        </div>

        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
          <div class="icon"><i class="bi bi-broadcast"></i></div>
          <h4 class="title"><a href="">Cloud Organic DevOps</a></h4>
          <p class="description">ur Cloud Organic DevOps service combines the power of DevOps principles with the scalability and flexibility of the cloud. We help you establish and optimize your DevOps practices, enabling seamless collaboration between development and operations teams. Our experts assist in setting up robust CI/CD pipelines, automated testing, infrastructure as code (IaC), and continuous monitoring, allowing you to release software faster, reduce time to market, and achieve greater agility in your development processes.</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
          <div class="icon"><i class="bi bi-brightness-high"></i></div>
          <h4 class="title"><a href="">Cloud Architecture and Solutions</a></h4>
          <p class="description">Our Cloud Architecture and Solutions service focuses on designing scalable, secure, and efficient cloud architectures on AWS. We work closely with you to understand your business objectives and technical requirements, and then develop customized cloud architectures that align with your goals. From high-level infrastructure design to network configuration, storage solutions, and disaster recovery planning, we ensure that your cloud environment is optimized for performance, reliability, and cost-efficiency.</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
          <div class="icon"><i class="bi bi-calendar2-week"></i></div>
          <h4 class="title"><a href="">24x7 Support</a></h4>
          <p class="description">Our 24x7 Support service provides round-the-clock assistance and technical support to ensure the continuous availability and smooth operation of your cloud infrastructure. Our dedicated support team is available to promptly address any issues, troubleshoot problems, and provide guidance whenever you need it. Whether it's resolving critical incidents, performing system upgrades, or answering technical queries, our support professionals are ready to deliver timely and reliable support, allowing you to focus on your business without interruptions.</p>
        </div>
      </div>

    </div>
  </section><!-- End Services Section -->
</template>
<script setup>
</script>