<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
  <div class="container" data-aos="fade-up">

    <div class="row no-gutters">
    <div class="col-lg-6 video-box">
      <img :src="require('../assets/img/about.jpg')" class="img-fluid" alt=""/>
    </div>

    <div class="col-lg-6 d-flex flex-column justify-content-center about-content">

      <div class="section-title">
      <h2>About Us</h2>
      <p>
        At DGG Cloud, we are a Singapore-based cloud consulting company born in the cloud and dedicated to providing exceptional AWS (Amazon Web Services) solutions. As a specialized AWS consulting partner, we focus solely on AWS technologies, enabling us to deliver unparalleled expertise and results for our clients.

        With a deep passion for cloud innovation, our team of highly skilled professionals brings extensive experience in architecting, implementing, and managing cloud solutions tailored to the unique needs of businesses across various industries. We understand that each organization has distinct goals and challenges, and we are committed to delivering custom AWS solutions that drive success and growth.
      </p>

      <div>
        <p class="section-title-apn-logo" style="text-align: center">
          <img :src="require('../assets/img/badge.png')" class="img-fluid" alt=""/>
        </p>
      </div>
      </div>

        <!--<div class="icon-box" data-aos="fade-up" data-aos-delay="100">
            <p>
                Our Core Value:
            </p>
        <div class="icon"><i class="bx bx-fingerprint"></i></div>
        <h4 class="title"><a href="">Expertise</a></h4>
        <p class="description">We pride ourselves on our in-depth knowledge and expertise in AWS technologies. Our team comprises AWS-certified professionals who stay up-to-date with the latest industry trends and best practices. This ensures that we can deliver cutting-edge solutions that leverage the full potential of the AWS platform.</p>
        </div>

        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
        <div class="icon"><i class="bx bx-gift"></i></div>
        <h4 class="title"><a href="">Nemo Enim</a></h4>
        <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
        </div>-->

    </div>
    </div>

  </div>
  </section><!-- End About Us Section -->
</template>
<script setup>
</script>