<template>
  <!-- ======= About Lists Section ======= -->
  <section class="about-lists">
    <div class="container">

      <div class="row no-gutters">

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up">
          <div>
            <img :src="require('../assets/img/service/icons/icons8-Approval.png')" class="img-fluid" alt="">
          </div>
          <h4>Expertise</h4>
          <p>We are dedicated to maintaining a high level of expertise in AWS technologies. Our team of AWS-certified professionals continuously enhances their skills and stays updated with the latest advancements in cloud computing.</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="100">
          <div>
            <img :src="require('../assets/img/service/icons/icons8-Online Support.png')" class="img-fluid" alt="">
          </div>
          <h4>Customer Focus</h4>
          <p>Our clients are at the heart of everything we do. We prioritize understanding their unique challenges, goals, and requirements.</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="200">
          <div>
            <img :src="require('../assets/img/service/icons/icons8-Mind Map.png')" class="img-fluid" alt="">
          </div>
          <h4>Innovation</h4>
          <p>We embrace a culture of innovation and encourage creative thinking within our team. We proactively explore new AWS services, emerging trends, and cutting-edge technologies.</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="300">
          <div>
            <img :src="require('../assets/img/service/icons/icons8-Privacy.png')" class="img-fluid" alt="">
          </div>
          <h4>Reliability</h4>
          <p>We are committed to providing reliable and trustworthy services to our clients. We understand the criticality of their business operations and ensure that our solutions are built with a focus on high availability, scalability, and security.</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="400">
          <div>
            <img :src="require('../assets/img/service/icons/icons8-Workflow.png')" class="img-fluid" alt="">
          </div>
          <h4>Collaboration</h4>
          <p>Collaboration is central to our approach. We believe that by fostering strong partnerships with our clients, we can achieve the best results.</p>
        </div>

        <div class="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="500">
          <div>
            <img :src="require('../assets/img/service/icons/icons8-Installing Updates.png')" class="img-fluid" alt="">
          </div>
          <h4>Continuous Learning</h4>
          <p>We embrace a growth mindset and encourage continuous learning within our team. We invest in ongoing training and professional development to stay ahead of the rapidly evolving cloud industry.</p>
        </div>

      </div>

    </div>
  </section><!-- End About Lists Section -->
</template>
<script setup>
</script>