<template>
  <HeroComponent/>
  <main id="main">
    <AboutComponent/>
    <AboutListsComponent/>
    <CountComponent/>
    <ServiceComponent/>
    <PortfolioComponent/>
    <FrequentlyAskedComponent/>
  </main><!-- End #main -->
</template>

<script setup>
import HeroComponent from '@/components/HeroComponent.vue';
import AboutComponent from '@/components/AboutComponent.vue';
import AboutListsComponent from '@/components/AboutListsComponent.vue';
import CountComponent from '@/components/CountComponent.vue';
import ServiceComponent from '@/components/ServiceComponent.vue';
import PortfolioComponent from '@/components/PortfolioComponent.vue';
import FrequentlyAskedComponent from '@/components/FrequentlyAskedComponent.vue';

import { ref, onMounted } from 'vue'

import axios from 'axios';
import Isotope from 'isotope-layout';
import AOS from 'aos';
import Swiper from 'swiper'
import PureCounter from '@srexi/purecounterjs'
import GLightbox from 'glightbox';

const url = '/contactInfo.json';

let contactInfo = ref({});

onMounted(() => {
  axios.get(url)
    .then((res) => {
      contactInfo.value = res.data;
    });

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };

  /**
   * Porfolio isotope and filter
   */
  window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item',
        layoutMode: 'fitRows'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function (e) {
        e.preventDefault();
        portfolioFilters.forEach(function (el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function () {
          AOS.refresh();
        });
      }, true);
    }

  });

  /**
   * Portfolio details slider
   */
  new Swiper('.portfolio-details-slider', {
    speed: 400,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });

  /**
   * Initiate portfolio lightbox
   */
  const portfolioLightbox = GLightbox({
    selector: '.portfolio-lightbox'
  });

  console.log(portfolioLightbox);

  /**
   * Initiate Pure Counter
   */
  new PureCounter();
})
</script>