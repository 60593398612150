<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero">
    <div class="hero-container">
      <div id="heroCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

        <ol class="carousel-indicators" id="hero-carousel-indicators">
          <li v-for="(card, index) in cards" :key="index" data-bs-target="#heroCarousel" :data-bs-slide-to="index" :class='index==0 ? "active" : ""'></li>
        </ol>

        <div class="carousel-inner" role="listbox">
          <!-- Slide 1 -->
          <div v-for="(card, index) in cards" :key="index" :class='index==0 ? "carousel-item active" : "carousel-item"' :style="{backgroundImage: 'url(' + require('../assets/img/slide/' + card.image) + ')'}">
            <div class="carousel-container">
              <div class="carousel-content container">
                <h2 class="animate__animated animate__fadeInDown">{{card.title}}</h2>
                <p class="animate__animated animate__fadeInUp">{{card.subtitle}}</p>
                <a :href="card.readMore" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
              </div>
            </div>
          </div>

        </div>

        <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
          <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
        </a>
        <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
          <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
        </a>

      </div>
    </div>
  </section><!-- End Hero -->
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
const url = '/heroCards.json';

let cards = ref({});

onMounted(() => {
  axios.get(url)
  .then((res)=>{
    cards.value = res.data;
    console.log(cards.value);
  })
})

</script>