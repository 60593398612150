<template>
  <main id="main" class="container">
    <section>
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>News</h2>
        </div>

        <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-lg-6 icon-box" data-aos="fade-up">
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02D3499rFdFUWLEVxT6Liqw6QEKAfLoxMoDAWgm1UkUWvMiBKPs66C26i2QgZkbdTl%26id%3D100093502602863&show_text=true&width=auto" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div class="col-lg-3"></div>

          <div class="col-lg-3"></div>
          <div class="col-lg-6 icon-box" data-aos="fade-up">
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid033S8VQePD8boNUgoDf7nAuvWwsi9S4L61dzu2yEAe2zCfiqbWq9L3V8zC9t4f3mnRl%26id%3D100093502602863&show_text=true&width=auto" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div class="col-lg-3"></div>

          <div class="col-lg-3"></div>
          <div class="col-lg-6 icon-box" data-aos="fade-up">
            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0L6KA26KfHJoHNw9ASyyoGfjGPJwRFX3BgCZtwxdzKfmLRTnkFegh7fp4testVdcgl%26id%3D100093502602863&show_text=true&width=auto" width="100%" height="600" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
          <div class="col-lg-3"></div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
</script>