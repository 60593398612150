<template>
  <!-- ======= Case Study Section ======= -->
  <section id="portfolio" class="portfolio section-bg">
    <div class="container" data-aos="fade-up" data-aos-delay="100">

      <div class="section-title">
        <h2>Case Study</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <div class="row">
        <div class="col-lg-12">
          <ul id="portfolio-flters">
          <li data-filter="*" class="filter-active">All</li>
          <li data-filter=".filter-gaming">i-Gaming</li>
          <li data-filter=".filter-web">Financial Services</li>
          </ul>
        </div>
      </div>

      <div class="row align-items-center portfolio-container">
        <div :class="'col-lg-4 col-md-6 portfolio-item ' + image.category" v-for="(image, index) in images" :key="index">
          <div class="portfolio-wrap" :style="{ width: '100%', paddingTop: 'calc(100% / ' + image.aspectRatio + ')' }">
            <img :src="image.src" class="img-fluid" alt="" >
            <div class="portfolio-info">
              <h4>{{ image.title }}</h4>
              <p>{{ image.description }}</p>
              <div class="portfolio-links">
                <a :href="image.href" data-gallery="portfolioGallery" class="portfolio-lightbox"><i class="bi bi-plus"></i></a>
                <!-- <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Case study Section -->
</template>
<script setup>
let images = [
  {
    category: 'filter-gaming',
    aspectRatio: 4 / 3,
    src: require('../assets/img/portfolio/gaming_1-1.jpg'),
    href: require('../assets/img/portfolio/gaming_1-2.png'),
    title: 'Global Gaming Entertainment Platform',
    description: 'Gaming',
  },
  {
    category: 'filter-gaming',
    aspectRatio: 4 / 3,
    src: require('../assets/img/portfolio/gaming_2-1.jpg'),
    href: require('../assets/img/portfolio/gaming_2-2.png'),
    title: 'Avatar Live Streaming',
    description: 'Gaming',
  },
  {
    category: 'filter-web',
    aspectRatio: 4 / 3,
    src: require('../assets/img/portfolio/web_1-1.jpg'),
    href: require('../assets/img/portfolio/web_1-2.png'),
    title: 'Panda Bank',
    description: 'Financial Services Industry',
  }
];
</script>